let origin = window.ORIGIN;
// 获取生活通子分类
const getLifeNewsTypeUrl = `${origin}/gateway/hc-neighbour/topic/getLifeNewsType`;
// 获取搜索历史
const getSearchHistoryUrl = `${origin}/gateway/hc-neighbour/topic/getSearchHistory`;
// 删除搜索历史
const deleteSearchHistoryUrl = `${origin}/gateway/hc-neighbour/topic/deleteSearchHistory`;
// 搜索
const getTopicListUrl = `${origin}/gateway/hc-neighbour/topic/getTopicList`;
// 获取热搜
const getHotSearchUrl = `${origin}/gateway/hc-neighbour/topic/getHotSearch`;
// 简单搜索
const getLifeTopicListUrl = `${origin}/gateway/hc-neighbour/topic/getLifeTopicList`;
// 查询话题详情
const getTopicInfoByIdURL = `${origin}/gateway/hc-neighbour/topic/getTopicInfoById`;

// 点赞
const addPraiseURL = `${origin}/gateway/hc-neighbour/topic/addPraise`;
// 取消点赞
const cancelPraiseURL = `${origin}/gateway/hc-neighbour/topic/cancelPraise`;
// 进行评论
const addCommentURL = `${origin}/gateway/hc-neighbour/topic/addComment`;
// 话题评论列表分页查询
const getTopicCommentList = `${origin}/gateway/hc-neighbour/topic/getTopicCommentList`;
// 评论回复列表分页查询
const getTopicReplyList = `${origin}/gateway/hc-neighbour/topic/getTopicReplyList`;

export {
  addPraiseURL,
  cancelPraiseURL,
  addCommentURL,
  getTopicCommentList,
  getTopicReplyList,
  getTopicInfoByIdURL,
  getLifeNewsTypeUrl,
  getSearchHistoryUrl,
  deleteSearchHistoryUrl,
  getTopicListUrl,
  getHotSearchUrl,
  getLifeTopicListUrl,
};
